import React from "react";
import '../styles/Services.css'
import Cabecera from "./Cabecerca";
import Servicio from "./Servicio";


function Services() {
    document.title = 'Servicios | Servicios Operativos de Ingenieria'
    return(
        <>
        <Cabecera title="Nuestros Servicios" />
        <Servicio  
                titulo_servicio="Cableado Estructurado"
                descripcion_servicio="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo."
                fondo = "/assets/services-img/Cableado-estructurado.jpeg.webp"
            />
             <Servicio  
                titulo_servicio="Electricidad"
                descripcion_servicio="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo."
                fondo = "/assets/services-img/electricidad.webp"
            />

            <Servicio  
                titulo_servicio="Sistema contra incendios"
                descripcion_servicio="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo."
                fondo = "/assets/services-img/sistemas-contraincendios.webp"
            />

            <Servicio  
                titulo_servicio="Sistemas de video"
                descripcion_servicio="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo."
                fondo = "/assets/services-img/sistemas-video.webp"
            />

            <Servicio  
                titulo_servicio="Alarmas"
                descripcion_servicio="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo."
                fondo = "/assets/services-img/alarmas.webp"
            />

            <Servicio  
                titulo_servicio="Aire Acondicionado"
                descripcion_servicio="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo."
                fondo = "/assets/services-img/aire-acondicionado.webp"
            />
</>
    )
}

export default Services