import React from "react";
import "../styles/Servicio.css"

function Servicio(props){
    return(
        <section className="servicio" 
        style={{ backgroundImage: `url(${props.fondo})` }}>
           <div className="servicio_content">
                <div className="content">
                    <div>
                        <h3>{props.titulo_servicio}</h3>
                    </div>
                    <div className="descripcion">
                        <p>{props.descripcion_servicio}</p>
                        
                    </div>
                    <button>
                            <a href="#">Solicite más información</a>
                    </button>
                </div> 
           </div>
        </section>
    )
}

export default Servicio