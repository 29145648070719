import { NavLink } from "react-router-dom";
import { useState } from "react";
import { Menu, X } from "lucide-react";
import "./navbar.css";

const NavLinks = ({ onClick }) => {
  return (
    <>
      <NavLink
        exact="true"
        to="/"
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        Inicio
      </NavLink>
      <NavLink
        exact="true"
        to="/nosotros"
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        Nosotros
      </NavLink>
      <NavLink
        exact="true"
        to="/noticias"
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        Noticias
      </NavLink>
      <NavLink
        exact="true"
        to="/productos"
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        Productos
      </NavLink>
      <NavLink
        exact="true"
        to="/servicios"
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        Servicios
      </NavLink>
      <NavLink
        exact="true"
        to="/proyectos"
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        Proyectos
      </NavLink>
      <NavLink
        exact="true"
        to="/contacto"
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        Contacto
      </NavLink>
    </>
  );
};

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false); // Cierra el menú al hacer clic en un enlace
  };

  return (
    <>
      <nav>
        <div className="closed_">
          <NavLinks onClick={handleMenuItemClick} />
        </div>
        <div className="burger">
          <button onClick={toggleNavbar}>
            {isOpen ? <X /> : <Menu />}
          </button>
        </div>
      </nav>
      {isOpen && (
        <div className="open_">
          <NavLinks onClick={handleMenuItemClick} />
        </div>
      )}
    </>
  );
};

export default Nav;
