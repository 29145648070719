import { NavLink } from 'react-router-dom';
import '../../styles/header/logo.css';

const Logo = () => {
    return(
        <>
        <div className="logo">
            <NavLink to="/" className={({ isActive }) => (isActive ? '' : '')}>
              <img src="/assets/Logo-soi.png" alt="Logo-SOI" />
            </NavLink>
          </div>
        </>
    )
}

export default Logo;