import React, { useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

import Logo from './header/Logo';
import Nav from './header/Nav'; // Revisa que la ruta esté bien
import '../styles/Header.css';  // Revisa que la ruta esté bien

const Header = () => {
  const [isStyled, setIsStyled] = useState(false);
  
  return (
    <>
      <div className="header">
        <div className={isStyled ? "menu-noclick":"header-sup"} setIsStyled={setIsStyled} >
          <Logo></Logo>
          <Nav></Nav>

          <div className="info">
            <div className='div-info'> 
              <FontAwesomeIcon icon={faPhone} size="2x" />
              <span>963307246 <br /> Llamar Ahora</span>
            </div>
            <div >
              <FontAwesomeIcon icon={faEnvelopeOpenText} size="2x" />
              <span>proyectos@soiperu.com <br /> ventas@soiperu.com</span>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Header;



