import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import {useForm} from 'react-hook-form'
import '../styles/Contact.css'
import Cabecera from './Cabecerca'


function Form(){
    

    const {register, handleSubmit,
         formState:{errors

    }} =useForm()
    return(
        <><section className='section-contact'>
            
        <div className="contact">
            
            <div className="info0">
                <h2>Contáctenos</h2>
                <div className="caja">
                    <div className="box-info">
                        <FontAwesomeIcon icon={faPhone} size='2x' color="#f1c50e" />
                        <ul>
                            <li>+51 963307246</li>
                        </ul>
                    </div>            
                    <div className="box-info">
                        <FontAwesomeIcon icon={faEnvelope} size='2x' color="#f1c50e"/>
                        <ul>
                            <li>gerencia@soiperu.com</li>
                            <li>ventas@soiperu.com</li>
                            <li>proyectos@soiperu.com</li>
                        </ul>
                    </div>
                    <div className="box-info">
                        <FontAwesomeIcon icon={faLocationDot} size='2x' color="#f1c50e"/>
                        <ul>
                            <li>Av. Progreso #385, 2do Piso.</li>
                            <li>Remigio Silva - Chiclayo - Lambayeque</li>
                        </ul>
                    </div>
                    <div className="box-info">

                    </div>
                </div>
            </div>
            <form action="" onSubmit={handleSubmit((data) =>{console.log(data)})}>
            
            <div>
                <label htmlFor="name">Nombres y Apellidos</label>
                <input type="text" name="nombre"placeholder="ejem: Juan Perez"
                {
                    ...register("nombre", {
                        required:{ value: true,
                        message: "Nombre es requerido"},
                        minLength:{
                            value: 2,
                            message: "El nombre debe tener al menos 2 caracteres"
                        }, 
                        pattern: {
                            value: /^[A-Za-zÁáÉéÍíÓóÚúÑñ\s]+$/,
                            message: "El nombre debe contener solo letras y espacios"
                        }
                    })
                }/>
                {
                    errors.nombre && <span className="errors">{errors.nombre.message}</span>
                }
            </div>
            <div>
                <label htmlFor="email">Correo Electronico</label>
                <input type="email" name="email" id="email" placeholder="juanperez@ejemplo.com"
                {
                    ...register("email", {
                        required:{ value: true,
                            message: "Correo es requerido"},
                            minLength:{
                                value: 5,
                                message: "El correo debe tener al menos 5 caracteres"
                            }, 
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Ingresa una dirección de correo electrónico válida"
                            }
                    })
                } />
                {
                    errors.email && <span className="errors">{errors.email.message}</span>
                }
            </div>
            <div>
                <label htmlFor="phone">Celular</label>
                <input type="tel" name="phone" id="phone" placeholder="ejm: 999999999"
                {
                    ...register("phone", {
                        required:{ value: true,
                            message: "ingrese un número de celular"},
                            maxLength:{
                                value: 9,
                                message: "El número de telefono puede tener como máximo 9 dígitos"
                            },minLength:{
                                value: 7,
                                message: "El número de telefono puede tener como minimo 7 dígitos"
                            }
                            , 
                            pattern: {
                                value: /^[0-9]*$/,
                                message: "Ingresa un número de teléfono válido"
                            }
                    })
                } />
                {
                    errors.phone && <span className="errors">{errors.phone.message}</span>
                }
            </div>
            <div>
                <label htmlFor="message">Mensaje</label>
                <textarea {
                    ...register("message", {
                        required: true
                    })
                }/>{
                    errors.message && <span className="errors">Escriba su mensaje</span>
                }

                
            </div>

            <input type="submit" value="Enviar Mensaje"/>
        </form>
        </div>
            </section>
        </>
    )
}

export default Form