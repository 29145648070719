import '../styles/nosotros.css'
import Cabecera from './Cabecerca'


function Nosotros() {
    document.title = 'Nosotros | Servicios Operativos de Ingenieria'
    return (
        <div>
            <div className="nosotros-container">
                <Cabecera title='Nosotros' />
                <div className="section-nosotros">
                    <img src="assets/nosotros-img/imagen_1 (2).png" alt="" />
                    <div className='text-nosotros'>
                        <h2>¿Quiénes somos?</h2>
                        <p>Empresa conformada por un equipo de ingenieros capacitados con experiencia que asumen el reto de brindar servicios tecnológicos en los diferentes campos de la ingeniería.
                            Para lo cual están en constante capacitación y estar a la par con los requerimientos de los clientes.
                        </p>
                    </div>

                </div>
                <div className="section-nosotros">
                    <div className='text-nosotros'>
                        <h2>Nuestra Visión </h2>
                        <p>Posicionarnos Como La Mejor Opción de Servicios Tecnológicos, En La Región. </p>
                    </div>
                    <img src="assets/nosotros-img/imagen_1 (1).png" alt="" />

                </div>
                <div className="section-nosotros">
                    <img src="assets/nosotros-img/imagen_1 (3).png" alt="" />
                    <div className='text-nosotros'>
                        <h2>Nuestra Misión </h2>
                        <p>Somos Una Empresa Que Brinda Servicios Tecnológicos. Buscando La Satisfacción Del Cliente En La Calidad, Tiempo y Precio.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nosotros