import React from "react";
import '../styles/cabecera.css'
function Cabecera(props){
    var fondoUrl;
    switch (props.title) {
      case 'Nosotros':
        fondoUrl = "/assets/services-img/cabeceras/nosotros.webp"
        break;
      case 'Noticias':
        fondoUrl = "/assets/services-img/cabeceras/imagen-prueba.jpg"
        break;
      case 'productos':
        fondoUrl = "/assets/services-img/cabeceras/productos.webp"
        break;
      case 'Nuestros Servicios':
        fondoUrl = "/assets/services-img/cabeceras/servicios.webp"
        break;
      case 'Nuestros Proyectos':
        fondoUrl = "/assets/services-img/cabeceras/imagen-prueba.jpg"
        break;
      case 'contacto':
        fondoUrl = "/assets/services-img/cabeceras/contacto.webp"
        break;            
      default:
         fondoUrl = "/assets/services-img/cabeceras/imagen-prueba.jpg"
        break;
    }

  
    return(
        <div className="cabecera" style={{ backgroundImage: `url(${fondoUrl})` }}>
            <h2>{props.title}</h2>
        </div>
    )
}

export default Cabecera